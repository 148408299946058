/* eslint-disable max-classes-per-file */
import { differenceInCalendarDays } from 'date-fns';
import i18n from '@/i18n';
import { PrimaryPolicyTypes } from '@/models/policy';
import { formatDate } from '@/utils/date-time';

export interface NeedsAttentionResponse {
  nonCompliant: NeedsAttentionNonCompliant;
}

export interface NeedsAttentionNonCompliant {
  critical: number;
  devices: Array<NeedsAttentionNonCompliantDevice>;
  high: number;
  low: number;
  medium: number;
  none: number;
  other: number;
  total: number;
}

export interface NeedsAttentionNonCompliantDevice {
  connected: boolean;
  customName: string;
  groupId: number;
  id: number;
  lastRefreshTime: string;
  name: string;
  needsReboot: boolean;
  os_family: string;
  policies: Array<NeedsAttentionNonCompliantDevicePolicy>;
  serverCreateTime: string;
}

export interface NeedsAttentionNonCompliantDevicePolicy {
  id: number;
  name: string;
  packages: Array<NeedsAttentionNonCompliantDevicePolicyPackage>;
  policyCreateTime: string;
  reasonForFail: string;
  severity: string;
  type: string;
}

export interface NeedsAttentionNonCompliantDevicePolicyPackage {
  createTime: string;
  id: number;
  name: string;
  packageVersionId: number;
  severity: string;
}

export interface NeedsAttentionContent {
  id?: string;
  device_id?: number;
  device_name?: string;
  has_policy?: boolean;
  policy?: string;
  policy_id?: number;
  status?: string;
  last_scanned?: string;
  issue?: string;
  issue_title?: string;
  issue_subtitle?: string;
  severity?: string;
  days_exposed?: number;
  first_seen?: string;
}

export class NeedsAttentionViewContent {
  rows: NeedsAttentionContent[];

  constructor(init: NeedsAttentionResponse) {
    if (!init) {
      this.rows = [];
    }
    const {
      nonCompliant: { devices = [] },
    } = init;
    const content: NeedsAttentionContent[] = [];

    // Rules for the rows in the report:
    // 1. Minimum one row per device:
    // a. If needsReboot = true, show Needs Reboot
    // b. Else if status = disconnected, show Disconnected
    // c. Else if no policies, show empty issues column for the device
    // d. Else there are policies, therefore ignore this requirement for this device
    // 2. Add one row per policy to devices that have policies
    devices.forEach((device) => {
      const commonResult = NeedsAttentionViewContent.getCommonResult(device);

      // Rule 1
      const minimumRowRequirementsResult =
        NeedsAttentionViewContent.getMimumRowRequirementResult(device);
      if (Object.keys(minimumRowRequirementsResult).length > 0) {
        content.push({ ...commonResult, ...minimumRowRequirementsResult });
      }

      // Rule 2
      device.policies.forEach((policy) => {
        content.push({
          ...commonResult,
          ...NeedsAttentionViewContent.getCommonPolicyResult(device.id, policy),
          ...NeedsAttentionViewContent.getPolicyByTypeResult(policy),
        });
      });
    });

    this.rows = content;
  }

  private static getCommonResult(
    device: NeedsAttentionNonCompliantDevice,
  ): NeedsAttentionContent {
    const lastRefreshTime: string =
      formatDate(device.lastRefreshTime) ||
      formatDate(new Date().toISOString());
    return {
      device_name: device.customName ? device.customName : device.name,
      device_id: device.id,
      status: device.connected
        ? i18n.t('general.statuses.connected')
        : i18n.t('general.statuses.disconnected'),
      last_scanned: lastRefreshTime,
    };
  }

  private static getMimumRowRequirementResult(
    device: NeedsAttentionNonCompliantDevice,
  ): NeedsAttentionContent {
    const deviceNeedsReboot = !!device.needsReboot;
    const deviceIsConnected = !!device.connected;

    let issue: string;
    if (deviceNeedsReboot) {
      issue = i18n.t('general.statuses.needsReboot');
    } else if (!deviceIsConnected) {
      issue = i18n.t('general.statuses.disconnected');
    } else if (device.policies.length === 0) {
      issue = ''; // Add row with blank issue if device does not need reboot, is connected, but does not have policies
    } else {
      return {}; // Do not add extra row if device does not need reboot, is connected, and has policies
    }

    return {
      id: `${device.id}:no_policy`,
      has_policy: false,
      policy: i18n.t('general.phrases.notApplicable'),
      issue,
      issue_title: issue,
      severity: i18n.t('general.severities.unknown'),
    };
  }

  private static getCommonPolicyResult(
    deviceId: number,
    policy: NeedsAttentionNonCompliantDevicePolicy,
  ): NeedsAttentionContent {
    return {
      id: `${deviceId}:${policy.id}`,
      has_policy: true,
      policy: policy.name,
      policy_id: policy.id,
      severity:
        policy.severity === 'other'
          ? i18n.t('general.severities.unknown')
          : policy.severity,
    };
  }

  private static getPolicyByTypeResult(
    policy: NeedsAttentionNonCompliantDevicePolicy,
  ): NeedsAttentionContent {
    let issueSubtitle = '';
    let daysExposed = -1;
    let firstSeen = '';

    if ((policy.type as PrimaryPolicyTypes) === PrimaryPolicyTypes.patch) {
      const packageData = policy.packages.map((packageDetails) => {
        return {
          name: packageDetails.name,
          createTime: packageDetails.createTime,
        };
      });
      let earliestCreateTime = '';
      [issueSubtitle, earliestCreateTime] =
        NeedsAttentionViewContent.processPackagesIdentifiedForPolicy(
          packageData,
        );
      daysExposed =
        earliestCreateTime === ''
          ? -1
          : differenceInCalendarDays(new Date(), new Date(earliestCreateTime));
      firstSeen = formatDate(earliestCreateTime);
    } else {
      issueSubtitle = policy.reasonForFail ? policy.reasonForFail : '';
    }

    const issueTitle = NeedsAttentionViewContent.getIssueTitleForPolicy(policy);
    return {
      // Note that we have `issue_title` and `issue_subtitle` for proper formatting
      // We also create `issue` to allow search functionality over the column
      issue: `${issueTitle} ${issueSubtitle}`,
      issue_title: issueTitle,
      issue_subtitle: issueSubtitle,
      days_exposed: daysExposed,
      first_seen: firstSeen,
    };
  }

  private static getIssueTitleForPolicy(
    policy: NeedsAttentionNonCompliantDevicePolicy,
  ): string {
    let issueTitle = '';
    if ((policy.type as PrimaryPolicyTypes) === PrimaryPolicyTypes.patch) {
      issueTitle = `${i18n.t(
        'reports.needsAttention.failedToInstall:phrase',
      )}: `;
    } else if (policy.type === PrimaryPolicyTypes.custom) {
      issueTitle = `${i18n.t(
        'reports.needsAttention.remediationFailed:phrase',
      )}: `;
    }
    return issueTitle;
  }

  private static processPackagesIdentifiedForPolicy(
    packageDetails: Array<{ name: string; createTime: string }>,
  ): string[] {
    let issueSubtitle = '';
    let earliestCreateTime = '';
    if (packageDetails.length > 0) {
      let earliestCreateDate = new Date();
      earliestCreateDate.setDate(earliestCreateDate.getDate() + 1);
      packageDetails.forEach((packageDetail) => {
        issueSubtitle += ` ${packageDetail.name},`;
        const currentCreateDate = new Date(packageDetail.createTime);
        if (currentCreateDate < earliestCreateDate) {
          earliestCreateDate = currentCreateDate;
        }
      });
      issueSubtitle = issueSubtitle.replace(/,$/, '');
      earliestCreateTime = earliestCreateDate.toISOString();
    }
    return [issueSubtitle, earliestCreateTime];
  }
}

import { NeedsAttentionResponse } from '@/models/needs-attention';
import httpClient from './http-client';

export const REPORTS_ENDPOINT = 'reports';
export const NEEDS_ATTENTION_ENDPOINT = 'needs-attention';
export function getNeedsAttention(
  query: string,
): Promise<NeedsAttentionResponse> {
  return httpClient
    .get(`${REPORTS_ENDPOINT}/${NEEDS_ATTENTION_ENDPOINT}?${query}`)
    .then((response) => response.data);
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-7",attrs:{"fluid":""}},[_c('ax-zone-breadcrumbs',{staticClass:"px-0"}),_c('v-row',{staticClass:"align-center justify-space-between flex-wrap"},[_c('v-col',{staticClass:"title"},[_c('h1',{staticClass:"pb-0 text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('reports.needsAttention.heading'))+" ")]),_c('h2',{staticClass:"text-capitalize",attrs:{"data-test-id":"na-group-name"}},[_vm._v(" "+_vm._s(_vm.$t('reports.needsAttention.pageSubtitle', { groupName: _vm.groupName }))+" ")])]),_c('v-col',{staticClass:"select-group",attrs:{"md":"auto"}},[_c('ax-reports-select-group',{attrs:{"device-groups":_vm.deviceGroups,"allow-all-groups":true,"path":_vm.$route.path}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('ax-text-field',{attrs:{"id":"search-needs-attention","value":_vm.queryState.search,"autocomplete":"off","background-color":"component","prepend-inner-icon":_vm.mdiMagnify,"label":_vm.$t('reports.needsAttention.search:label'),"clearable":"","hide-details":""},on:{"input":_vm.updateSearch}})],1)],1),_c('v-row',[_c('v-col',[_c('ax-table',_vm._g({attrs:{"headers":_vm.headers,"footer-props":_vm.footer,"items":_vm.content,"options":_vm.tableState,"search":_vm.queryState.search,"column-select":false,"item-key":"id","no-data-text":_vm.$t('reports.needsAttention.table.noResults'),"data-test-id":"needs-attention-table"},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.device_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'deviceDetails',
              query: { o: _vm.orgId },
              params: {
                deviceId: item.device_id,
              },
            }}},[_vm._v(" "+_vm._s(item.device_name)+" ")])]}},{key:"item.policy",fn:function(ref){
            var item = ref.item;
return [(item.has_policy)?_c('router-link',{attrs:{"to":{
              name: _vm.PolicyRouteNames.PolicyForm,
              query: { o: _vm.orgId },
              params: { policyId: item.policy_id },
            }}},[_vm._v(" "+_vm._s(item.policy)+" ")]):_c('span',[_vm._v(_vm._s(item.policy))])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))])]}},{key:"item.issue",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"fw-black"},[_vm._v(_vm._s(item.issue_title))]),_c('span',[_vm._v(_vm._s(item.issue_subtitle))])]}},{key:"item.severity",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.severity))])]}},{key:"item.days_exposed",fn:function(ref){
            var item = ref.item;
return [(item.days_exposed >= 0)?_c('span',{staticClass:"tw-whitespace-pre"},[_vm._v(_vm._s(item.days_exposed))]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(item.first_seen ? _vm.$t('reports.needsAttention.firstSeen') : '-')+" "+_vm._s(item.first_seen)+" ")])]}}])},_vm.$listeners))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }